<template>
  <div>
    <LuckyWheel ref="myLucky" width="350px" height="350px" :prizes="prizes" :blocks="blocks" :buttons="buttons" @start="startCallback" @end="endCallback" />
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      blocks: [{ padding: "13px", background: "#f14629" }],

      buttons: [
        { radius: "50px", background: "#b4141f" },
        { radius: "45px", background: "#f66569" },
        {
          radius: "40px",
          background: "#e33038", //指针

          pointer: true,
          fonts: [{ text: "开始\n抽奖", top: "-20px", fontColor: "#fff" }]
        }
      ],
      draw: {}
    };
  },
  computed: {
    prizes() {
      const { get_prize = [] } = this.draw;

      return get_prize.map((a, i) => {
        return {
          drawId: a.draw_id,
          prizeId: a.prize_id,
          range: a.weight * 100,
          background: i % 2 == 0 ? "#fdbf34" : "#f27a26",
          fonts: [{ text: a.prize_name, top: "70px", fontColor: "#fff" }],
          imgs: [{ src: a.prize_pic_url, width: "50px", height: "50px", top: "10px" }]
        };
      });
    }
  },
  methods: {
    getDraw() {
      this.$get("/home/draw/20").then(res => {
        this.draw = res;
      });
    },
    // 点击抽奖按钮会触发star回调
    startCallback() {
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play();
      // 调用接口异步抽奖
      setTimeout(() => {
        // // 假设后端返回的中奖索引是0
        // const index = 0
        // // 调用stop停止旋转并传递中奖索引
        // this.$refs.myLucky.stop(index)
        this.$refs.myLucky.stop();
      }, 3000);
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log("prize", prize);
      this.$post('/home/drawMember', {
        member_id: this.$store.state.member.id,
        draw_id: prize.drawId,
        prize_id: prize.prizeId
      }).then(res => {
        console.log("res", res);


      })
    }
  },
  created() {
    this.getDraw();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common";
</style>