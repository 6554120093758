<template>
  <div class="brands">

    <NavHeader />
<luckyWheel style="position: absolute; top: 200px; right: 70px;z-index:999"></luckyWheel>
    <div class="content_container content">
      <div class="banner">
        <img src="../../assets/images/cj.jpg">

      </div>
      <p class="title">
        <img src="../../assets/images/tit4.png">
      </p>
      <div
        v-if="couponList.length > 0"
        class="couponList"
      >
        <div
          class="coupon"
          v-for="(item, index) in couponList"
          :key="index"
        >
          <ul>
            <li class="left">
              ￥<span> {{ item.money }}</span>
            </li>
            <li class="center">
              <p class="name">{{ item.name }}</p>
              <p class="condition">使用条件 (金额)<span> {{item.condition}}</span>元</p>
              <p style="font-size: 10px">
                {{ new Date(item.use_start).format('yyyy-MM-dd') }}至{{ new Date(item.use_end).format('yyyy-MM-dd') }}
              </p>
            </li>
            <li class="right"><button @click="receive(item)">领取</button></li>
          </ul>
        </div>
      </div>
      <p class="center">
        <el-pagination
          @current-change="currentChange"
          :current-page.sync="where.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size.sync="where.limit"
          :total="total"
        >
        </el-pagination>
      </p>
    </div>

  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";
import luckyWheel from "@/components/luckyWheel";

export default {
  components: {
    NavHeader,
    luckyWheel
  },
  data() {
    return {
      couponList: [],
      where: {
        page: 1,
        limit: 10
      }
    };
  },
  computed: {},
  mounted() {
    this.getCoupon();
    this.currentChange();
  },
  methods: {
    //领取优惠券
    receive(item) {
      this.$post("home/coupon_receive", {
        coupon_id: item.id,
        member_id: this.$store.state.member.id
      }).then(res => {
        this.$message.success("领取成功");
      });
    },
    getCoupon() {
      this.$get("home/coupon").then(res => {
        this.couponList = res.items.data;
      });
    },
    currentChange() {
      this.$get("admin/shop", this.where).then(res => {
        this.shop = res.items.data;
        this.total = res.items.total;
      });
    }
  },

  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.content_container {
  background: #fff;
}
.title {
  text-align: center;
  padding: 30px;
  img {
    width: 400px;
  }
}
.brands {
  min-height: 600px;
}
.banner {
  width: 100%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.couponList::after {
  display: block;
  clear: both;
  content: "";
}
.couponList {
  padding: 0 10px;
  margin: 0 -10px;
  display: block;
  clear: both;
  content: "";
  min-height: 800px;

  .coupon {
    border: 1px solid #eee;
    margin: 10px;

    float: left;
    width: 380px;
    box-sizing: border-box;

    height: 170px;

    color: #666;

    box-sizing: border-box;

    ul {
      border-radius: 10px;

      border: 10px solid #fff;
      padding: 10px 0;
      box-sizing: border-box;
      box-sizing: border-box;
      background: #fff4f2;

      height: 100%;
      @include space-between;

      .left {
        color: red;
        width: 100px;
        @include center;
        span {
          font-weight: bold;
          font-size: 24px;
        }
      }
      .center {
        text-align: left;
        padding: 10px;
        p {
          width: 100%;
        }
        flex: 1;
        width: 150px;
        @include wrap-between;
        .name {
          font-weight: bold;
          font-size: 16px;
        }
        .condition {
          span {
          }
        }
      }
      .right {
        width: 80px;
        @include center;
        button {
          width: 70px;
          height: 30px;
          border-radius: 20px;
          border: 0;
          @include center;
          background: red;
          color: #fff;
        }
      }
    }
  }
}
</style>
 